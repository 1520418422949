<app-nav-bar activatedItem="drones"></app-nav-bar>

<div id="page-content">
  <app-exova-map></app-exova-map>

  <div id="nav">
    <span class="nav-button pi pi-chevron-left"
          (click)="previousEvent()"
          [class.disable]="droneDetections.length < 2">
    </span>
    <span>{{ droneDetectionIndex + 1 | appNumber }}</span>
    /
    <span>{{ droneDetections.length | appNumber }}</span>
    <span class="nav-button pi pi-chevron-right"
          (click)="nextEvent()"
          [class.disable]="droneDetections.length < 2">
    </span>
  </div>

  <div id="bottom-right-content" *ngIf="droneDetection">
    <div>
      <br>
      <strong>
        Scenario {{ label }}:
      </strong>
      <br>
      {{ droneDetection.uav.localDateTime[0] | date:'dd.MM.yyyy' }}
      |
      {{ droneDetection.uav.localDateTime[0] | date:'HH:mm:ss' }}
      -
      {{ droneDetection.uav.localDateTime[droneDetection.uav.localDateTime.length-1] | date:'HH:mm:ss' }}
      <br>
      {{ droneDetection.uav.ref }}
      <br>
      {{ droneDetection.uav.scenario }}
      <br>
      <br>
      <hr>
      <br>
      <br>
      <div class="time-and-play-controls">
        {{ droneDetection.uav.localDateTime[timeIndex] | date:'dd.MM.yyyy HH:mm:ss' }}
        <div class="play-controls">
          <div *ngIf="!playing" class="button pi pi-play" (click)="play()"></div>
          <div *ngIf="playing" class="button pi pi-stop" (click)="stop()"></div>
          <ng-container *ngFor="let speed of [1, 2, 5, 10]">
            <div *ngIf="playing"
                 class="button speed-button"
                 [class.current-speed]="speed === playSpeed"
                 (click)="changeSpeed(speed)"
            >{{ speed }}x
            </div>
          </ng-container>
        </div>
      </div>
      <p-slider
        [(ngModel)]="timeIndex"
        [min]="0"
        [max]="droneDetection.uav.lonDeg.length - 1"
        [step]="1"
        (onChange)="updateData()"
      ></p-slider>
      <br>
      <br>
      <ng-container *ngFor="let sensor of droneDetection.sensors">
        <strong>Sensor {{ sensor.ref }}</strong>
        <br>
        Detected azimuth : {{ sensor.detecAziDeg[timeIndex] | appNumber:'.0-0' }}°
        <br>
        Detected elevation : {{ sensor.detecEleDeg[timeIndex] | appNumber:'.0-0' }}°
        <br>
        <br>
      </ng-container>
    </div>
  </div>
</div>
