import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {GraphsComponent} from './pages/graphs/graphs.component';
import {ListComponent} from './pages/list/list.component';
import {LivescopeComponent} from './pages/livescope/livescope.component';
import {EventComponent} from './pages/event/event.component';
import {LoginComponent} from './pages/login/login.component';
import {UsersComponent} from './pages/user/users.component';
import {AuthGuard} from './guard/auth.guard';
import {PasswordComponent} from './pages/password/password.component';
import {PasswordProxyComponent} from './pages/password/password-proxy/password-proxy.component';
import {InfrasonicEventExampleComponent} from './pages/infrasonic-event-example/infrasonic-event-example.component';
import {StationsComponent} from './pages/stations/stations.component';
import {StationComponent} from './pages/station/station.component';
import {MyAccountComponent} from './pages/my-account/my-account.component';
import {NotificationsComponent} from './pages/notifications/notifications.component';
import {HomeComponent} from './pages/home/home.component';
import {LegalNoticeComponent} from './pages/legal-notice/legal-notice.component';
import {ShotDetectionComponent} from 'src/app/pages/shot-detection/shot-detection.component';
import {RestrictedAccessConditionsComponent} from 'src/app/pages/restricted-access-conditions/restricted-access-conditions.component';
import {DroneDetectionComponent} from 'src/app/pages/drone-detection/drone-detection/drone-detection.component';

const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  {path: 'legal-notice', component: LegalNoticeComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  {path: 'livescope', component: LivescopeComponent, pathMatch: 'full', canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'restricted-access-conditions', component: RestrictedAccessConditionsComponent, canActivate: [AuthGuard]},
  {path: 'infrasonic-event-examples', component: InfrasonicEventExampleComponent, canActivate: [AuthGuard]},
  {path: 'graphs', component: GraphsComponent, canActivate: [AuthGuard]},
  {path: 'users', component: UsersComponent, canActivate: [AuthGuard]},
  {path: 'stations', component: StationsComponent, canActivate: [AuthGuard]},
  {path: 'station/:id', component: StationComponent, canActivate: [AuthGuard]},
  {path: 'my-account', component: MyAccountComponent, canActivate: [AuthGuard]},
  {path: 'notifications', component: NotificationsComponent, canActivate: [AuthGuard]},

  {path: 'event/:id', component: EventComponent, canActivate: [AuthGuard]},
  {path: 'shot-detection', component: ShotDetectionComponent, canActivate: [AuthGuard]},
  {path: 'list', component: ListComponent, canActivate: [AuthGuard]},
  {path: 'drones', component: DroneDetectionComponent, canActivate: [AuthGuard]},
  {path: 'createPassword', component: PasswordProxyComponent},
  {path: 'resetPassword', component: PasswordProxyComponent},
  {path: 'password', component: PasswordComponent},
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {})
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule {
}
