import {AfterViewInit, ChangeDetectorRef, Component, NgZone, OnInit, Renderer2} from '@angular/core';
import mapboxgl, {
  AttributionControl,
  FullscreenControl,
  GeoJSONSourceRaw,
  LinePaint,
  Marker,
  NavigationControl,
  Point,
  Popup,
  ScaleControl
} from 'mapbox-gl';
import {BehaviorSubject, filter, firstValueFrom} from 'rxjs';
import {LngLatUtils} from 'src/app/pages/shot-detection/LngLatUtils';

@Component({
  selector: 'app-exova-map',
  templateUrl: './exova-map.component.html',
  styleUrls: ['./exova-map.component.scss']
})
export class ExovaMapComponent implements OnInit, AfterViewInit {

  mapboxToken = 'pk.eyJ1IjoiYWJvbm9ibyIsImEiOiJjbG4zOGN4amkwa2h5MmlsN21sMnBzbmF0In0.os3s9yq1QPoDILWWnkEwBg';
  mapboxStyle = 'mapbox://styles/mapbox/satellite-streets-v12';

  mapPresent = true;
  map: mapboxgl.Map;
  readonly mapLoaded = new BehaviorSubject(false);

  constructor(private renderer: Renderer2,
              private ngZone: NgZone,
              private changeDetector: ChangeDetectorRef) {
  }

  private nextSourceIdNumber = 0;
  private sourcesIds: string[] = [];
  private nextLayerIdNumber = 0;
  private layersIds: string[] = [];
  private markers: Marker[] = [];

  ngOnInit(): void {
    mapboxgl.accessToken = this.mapboxToken;
    // setTimeout(() => {


    // }, 500);
  }

  ngAfterViewInit(): void {
    this.loadMap();
  }

  private loadMap(): void {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: this.mapboxStyle,
      center: [9.3164, 47.1112],
      zoom: 17,
      attributionControl: false,
      logoPosition: 'bottom-left',
    });
    this.map.addControl(new ScaleControl({maxWidth: 300}));
    this.map.addControl(new AttributionControl(), 'bottom-left');
    this.map.addControl(new FullscreenControl());
    this.map.addControl(new NavigationControl({visualizePitch: true}));
    this.map.on('load', () => {
      this.mapLoaded.next(true);
    });
  }

  async waitMapLoad() {
    const mapLoadedTrueObservable = this.mapLoaded
      .pipe(filter(loaded => loaded));
    await firstValueFrom(mapLoadedTrueObservable);
  }

  reloadMap() {
    this.mapLoaded.next(false);
    const freeCameraOptions = this.map.getFreeCameraOptions();
    this.clearMap();
    this.map.remove();
    this.loadMap();
    this.map.setFreeCameraOptions(freeCameraOptions);
  }

  clearMap() {
    this.layersIds.forEach(layerId => this.map.removeLayer(layerId));
    this.layersIds = [];
    this.nextLayerIdNumber = 0;
    this.sourcesIds.forEach(sourceId => this.map.removeSource(sourceId));
    this.sourcesIds = [];
    this.nextSourceIdNumber = 0;
    this.markers.forEach(marker => marker.remove());
    this.markers = [];
  }

  addMarker(lngLat: [number, number], options?: {
    classNames?: string[],
    image?: string,
    coloredImage?: boolean,
    color?: string,
    opacity?: number,
    rotation?: number,
    width?: number,
    offset?: Point,
    hoverHtml?: string,
    shadowColor?: string,
    shadowSize?: number
  }): Marker {
    const rotation = options?.rotation ?? 0;
    const markerElement = this.renderer.createElement('div') as HTMLElement;
    markerElement.classList.add('marker', ...options?.classNames ?? []);
    markerElement.classList.add(options?.coloredImage === true ? 'not-mask' : 'mask');
    if (options?.image !== undefined) {
      markerElement.style.setProperty('--marker-image', `url('/assets/map/${options.image}')`);
    }
    if (options?.color !== undefined) {
      markerElement.style.setProperty('--marker-color', options.color);
    }
    if (options?.opacity !== undefined) {
      markerElement.style.setProperty('--marker-opacity', '' + options.opacity);
    }
    if (options?.width !== undefined) {
      markerElement.style.setProperty('--marker-width', options.width + 'px');
    }
    if (options?.shadowColor !== undefined) {
      markerElement.style.setProperty('--marker-shadow-color', options.shadowColor);
    } else {
      markerElement.style.setProperty('--marker-shadow-color', 'transparent');
    }
    if (options?.shadowSize !== undefined) {
      markerElement.style.setProperty('--marker-shadow-size', options.shadowSize + 'px');
    } else {
      markerElement.style.setProperty('--marker-shadow-size', '0');
    }
    const marker = new Marker(markerElement)
      .setLngLat(lngLat)
      .setRotation(rotation)
      .setRotationAlignment('map')
      .addTo(this.map);
    if (options?.offset !== undefined) {
      marker.setOffset(options.offset);
    }
    this.markers.push(marker);
    const hasPopup = options?.hoverHtml !== undefined;
    if (hasPopup) {
      marker.setPopup(new Popup({closeButton: false})
        .setHTML(options.hoverHtml));
      if (options?.offset !== undefined) {
        marker.getPopup().setOffset(options.offset.rotate(rotation / 180 * Math.PI));
      }
    }
    markerElement.addEventListener('mouseenter', ev => {
      if (hasPopup) {
        marker.getPopup().addTo(this.map);
      }
      markerElement.classList.add('hover');
    });
    markerElement.addEventListener('mouseleave', ev => {
      if (hasPopup) {
        marker.getPopup().remove();
      }
      markerElement.classList.remove('hover');
    });
    return marker;
  }

  drawLine(lngLats: number[][], options?: { color?: string, hoverText?: string, width?: number, linePaint?: LinePaint }): string {
    const color = options?.color ?? 'red';
    const width = options?.width ?? 3;
    const sourceId = this.nextSourceId();
    const layerId = this.nextLayerId();
    const geoJsonLine = this.geoJsonLine(lngLats);
    this.map.addSource(sourceId, geoJsonLine);
    this.sourcesIds.push(sourceId);
    this.map.addLayer({
      id: layerId,
      type: 'line',
      source: sourceId,
      paint: {
        'line-color': color,
        'line-width': width,
        ...options.linePaint
      }
    });
    this.layersIds.push(layerId);
    if (options?.hoverText !== undefined) {
      const popup = new Popup({closeButton: false})
        .setHTML(options.hoverText);
      this.map.on('mouseenter', layerId, ev => {
        popup.setLngLat(ev.lngLat);
        popup.addTo(this.map);
        this.map.setPaintProperty(layerId, 'line-width', 3 * width);
      });
      this.map.on('mouseleave', layerId, ev => {
        popup.remove();
        this.map.setPaintProperty(layerId, 'line-width', width);
      });
    }
    return layerId;
  }

  updateLine(sourceId: string, lngLats: number[][]) {
    const geoJsonLine = this.geoJsonLine(lngLats);
    const source: any = this.map.getSource(sourceId);
    source.setData(geoJsonLine.data);
  }

  drawImage(imageUrl: string): string {
    const centerLngLat = [9.316241438188554, 47.1112397711204];
    const widthM = 600;
    const heightM = 600;
    const sizeLngLat = LngLatUtils.dXYm2dLngLat(widthM, heightM, centerLngLat[1]);
    const leftLng = centerLngLat[0] - sizeLngLat[0] / 2;
    const rightLng = centerLngLat[0] + sizeLngLat[0] / 2;
    const topLat = centerLngLat[1] + sizeLngLat[1] / 2;
    const bottomLat = centerLngLat[1] - sizeLngLat[1] / 2;
    const sourceId = this.nextSourceId();
    const layerId = this.nextLayerId();
    this.map.addSource(sourceId, {
      type: 'image',
      url: imageUrl,
      coordinates: [
        [leftLng, topLat],
        [rightLng, topLat],
        [rightLng, bottomLat],
        [leftLng, bottomLat],
      ],
    });
    this.sourcesIds.push(sourceId);
    this.map.addLayer({
      id: layerId,
      type: 'raster',
      source: sourceId,
    });
    this.layersIds.push(layerId);
    return layerId;
  }

  private nextSourceId(): string {
    const nextSourceId = 'source-' + this.nextSourceIdNumber;
    this.nextSourceIdNumber++;
    return nextSourceId;
  }

  private nextLayerId(): string {
    const nextLayerId = 'layer-' + this.nextLayerIdNumber;
    this.nextLayerIdNumber++;
    return nextLayerId;
  }

  private geoJsonLine(lngLats: number[][]): GeoJSONSourceRaw {
    return {
      type: 'geojson',
      data: {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: lngLats
        }
      }
    };
  }

}
