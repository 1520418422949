import {AfterViewInit, Component, ElementRef, OnDestroy, ViewChild} from '@angular/core';
import {ExovaMapComponent} from 'src/app/common/exova-map/exova-map/exova-map.component';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {ExovaColors} from 'src/app/pages/shot-detection/ExovaColors';
import {DroneDetection} from 'src/app/services/arfang-ws/drone-detection/drone-detection-ws-model';
import {DroneDetectionWsService} from 'src/app/services/arfang-ws/drone-detection/drone-detection-ws.service';
import {Marker, Point} from 'mapbox-gl';

@Component({
  selector: 'app-drone-detection',
  templateUrl: './drone-detection.component.html',
  styleUrls: ['./drone-detection.component.scss']
})
export class DroneDetectionComponent implements AfterViewInit, OnDestroy {

  @ViewChild(ExovaMapComponent)
  exovaMap: ExovaMapComponent;

  droneDetectionId?: string;
  droneDetectionIndex = 0;
  skipNextQueryParamsChange = false;

  droneDetections: DroneDetection[] = [];
  droneDetection: DroneDetection;
  scopeEpochSecond = 0;
  private eventsLoadedSinceMapReloaded = 0;
  private loadingEvent = false;

  droneMarker: Marker;
  sensorDetectionArrowsMarkers: Marker[] = [];
  timeIndex = 0;
  playing = false;
  private playingId = 0;
  playSpeed = 1;

  private subscriptions: Subscription[] = [];

  constructor(private droneDetectionWsService: DroneDetectionWsService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private elementRef: ElementRef) {
  }

  async ngAfterViewInit(): Promise<void> {
    this.droneDetections = await this.droneDetectionWsService.getDroneDetections();
    ExovaColors.addCssVariablesToHtmlElement(this.elementRef.nativeElement);
    this.subscriptions.push(this.activatedRoute.queryParams.subscribe(queryParams => {
      if (this.skipNextQueryParamsChange) {
        this.skipNextQueryParamsChange = false;
        return;
      }
      this.droneDetectionId = queryParams.shotDetectionId;
      if (queryParams.ref) {
        this.droneDetectionIndex = this.droneDetections.findIndex(d => d.ref === queryParams.ref);
        if (this.droneDetectionIndex === -1) {
          this.droneDetectionIndex = 0;
        }
      } else {
        this.droneDetectionIndex = 0;
      }
      this.loadEvent();
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  previousEvent() {
    if (this.loadingEvent) {
      return;
    }
    this.droneDetectionIndex--;
    if (this.droneDetectionIndex < 0) {
      this.droneDetectionIndex = this.droneDetections.length - 1;
    }
    this.navigateToEventIndex();
  }

  nextEvent() {
    if (this.loadingEvent) {
      return;
    }
    this.droneDetectionIndex++;
    if (this.droneDetectionIndex >= this.droneDetections.length) {
      this.droneDetectionIndex = 0;
    }
    this.navigateToEventIndex();
  }

  private navigateToEventIndex() {
    this.router.navigate(['.'], {
      relativeTo: this.activatedRoute,
      queryParamsHandling: 'merge',
      queryParams: {ref: this.droneDetections[this.droneDetectionIndex].ref},
      replaceUrl: true
    });
  }

  private async loadEvent() {
    this.loadingEvent = true;
    try {
      this.exovaMap.clearMap();
      this.eventsLoadedSinceMapReloaded++;
      if (this.eventsLoadedSinceMapReloaded > 10) {
        this.eventsLoadedSinceMapReloaded = 0;
        this.exovaMap.reloadMap();
      }
      this.droneDetection = this.droneDetections[this.droneDetectionIndex];
      this.droneDetectionId = this.droneDetection.ref;
      this.timeIndex = 0;
      this.scopeEpochSecond = Math.round(Date.parse(this.droneDetection.uav.localDateTime[0]) / 1000);
      await this.exovaMap.waitMapLoad();
      this.exovaMap.clearMap();
      this.drawSensors();
      this.drawScenarioData();
      this.drawDrone();
      this.updateData();
    } catch (error) {
      console.log(error);
      this.droneDetection = undefined;
    }
    this.loadingEvent = false;
  }

  drawSensors() {
    this.sensorDetectionArrowsMarkers = [];
    this.droneDetection.sensors.forEach(sensor => {
      this.exovaMap.addMarker([sensor.lonDeg, sensor.latDeg], {
        image: 'an16.png',
        coloredImage: true,
        width: 35,
        hoverHtml: sensor.ref,
      });
      const arrowMarker = this.exovaMap.addMarker([sensor.lonDeg, sensor.latDeg], {
        image: 'arrow.svg',
        color: 'transparent',
        width: 50,
        rotation: sensor.detecAziDeg[this.timeIndex],
        offset: new Point(0, -30),
      });
      this.sensorDetectionArrowsMarkers.push(arrowMarker);
    });
  }

  drawScenarioData() {
    this.drawUAVPath();
  }

  drawDrone() {
    this.droneMarker = this.exovaMap.addMarker([this.droneDetection.uav.lonDeg[0], this.droneDetection.uav.latDeg[0]], {
      image: 'drone.png',
      width: 80,
      coloredImage: true,
      shadowColor: 'red',
      shadowSize: 10,
      hoverHtml: 'Drone position from scenario data'
    });
  }

  play() {
    this.playing = true;
    this.playingId++;
    if (this.timeIndex >= this.droneDetection.uav.latDeg.length - 1) {
      this.timeIndex = 0;
    }
    this.waitAndUpdateTimeIndex();
  }

  stop() {
    this.playing = false;
    this.playingId++;
  }

  changeSpeed(speed: number) {
    this.playSpeed = speed;
  }

  waitAndUpdateTimeIndex() {
    this.updateData();
    const currentPlayingId = this.playingId;
    setTimeout(() => {
      if (this.playingId !== currentPlayingId) {
        return;
      }
      if (this.timeIndex >= this.droneDetection.uav.latDeg.length - 1) {
        this.stop();
        return;
      }
      this.timeIndex++;
      this.waitAndUpdateTimeIndex();
    }, 1000 / this.playSpeed);
  }

  updateData() {
    const lngLats: [number, number][] = [];
    for (let i = 0; i <= this.timeIndex && i < this.droneDetection.uav.latDeg.length; i++) {
      const lat = this.droneDetection.uav.latDeg[i];
      const lng = this.droneDetection.uav.lonDeg[i];
      lngLats.push([lng, lat]);
    }
    this.exovaMap.updateLine('source-0', lngLats.slice(0, this.timeIndex + 1));
    this.droneMarker.setLngLat([this.droneDetection.uav.lonDeg[this.timeIndex], this.droneDetection.uav.latDeg[this.timeIndex]]);
    this.droneDetection.sensors.forEach((sensor, i) => {
      const arrowMarker = this.sensorDetectionArrowsMarkers[i];
      const detecAziDeg = sensor.detecAziDeg[this.timeIndex];
      arrowMarker.setRotation(detecAziDeg);
      arrowMarker.getElement().style.setProperty('--marker-color', detecAziDeg === null ? 'transparent' : 'blue');
    });
  }

  drawUAVPath() {
    const lngLats: [number, number][] = [];
    for (let i = 0; i < this.droneDetection.uav.latDeg.length; i++) {
      const lat = this.droneDetection.uav.latDeg[i];
      const lng = this.droneDetection.uav.lonDeg[i];
      lngLats.push([lng, lat]);
    }
    this.exovaMap.drawLine(lngLats, {
      color: '#ff0000',
      linePaint: {'line-dasharray': [4, 4]},
      width: 1.5,
      hoverText: 'UAV path from scenario data'
    });
  }

  get label(): string {
    return this.droneDetection?.ref;
  }

}
