import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {LiveScopeSessionComponent} from './pages/livescope/live-scope-session/live-scope-session.component';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatIconModule} from '@angular/material/icon';
import {SensorScopeComponent} from './pages/livescope/live-scope-session/scopes/sensor-scope/sensor-scope.component';
import {SpectrogramComponent} from './pages/livescope/live-scope-session/scopes/spectrogram/spectrogram.component';
import {WaterfallComponent} from './pages/livescope/live-scope-session/scopes/waterfall/waterfall.component';
import {AppRoutingModule} from './app-routing.module';
import {GraphsComponent} from './pages/graphs/graphs.component';
import {PlotterComponent} from './pages/graphs/plotter/plotter.component';
import {MapComponent} from './common/map/map.component';
import {ArfangEventsFiltersComponent} from './common/events-filter/arfang-events-filters.component';
import {NavBarComponent} from './common/nav-bar/nav-bar.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {PeriodSelectorComponent} from './common/events-filter/period-selector/period-selector.component';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {PropertiesSelectorComponent} from './common/events-filter/properties-selector/properties-selector.component';
import {ListComponent} from './pages/list/list.component';
import {EventsTableComponent} from './pages/list/events-table/events-table.component';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatSortModule} from '@angular/material/sort';
import {LivescopeComponent} from './pages/livescope/livescope.component';
import {EventComponent} from './pages/event/event.component';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {SvgSectorComponent} from './common/svg/svg-sector/svg-sector.component';
import {SvgMagnitudeComponent} from './common/svg/svg-magnitude/svg-magnitude.component';
import {LoginComponent} from './pages/login/login.component';
import {UsersComponent} from './pages/user/users.component';
import {AuthInterceptor} from './services/auth/auth.interceptor';
import {ConfigurationService} from './services/configuration.service';
import {appInitializer} from './app-initializer';
import {UserCreateComponent} from './pages/user/user-create/user-create.component';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {AlertDialogComponent} from './common/alert-dialog/alert-dialog.component';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {AccountComponent} from './pages/user/account/account.component';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';
import {PasswordComponent} from './pages/password/password.component';
import {PasswordProxyComponent} from './pages/password/password-proxy/password-proxy.component';
import {InfrasonicEventExampleComponent} from './pages/infrasonic-event-example/infrasonic-event-example.component';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatDividerModule} from '@angular/material/divider';
import {AzimuthCorrelogramComponent} from './pages/livescope/live-scope-session/scopes/azimuth-correlogram/azimuth-correlogram.component';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';

import * as PlotlyJS from 'plotly.js-dist-min';
import {PlotlyModule} from 'angular-plotly.js';
import {StationsComponent} from './pages/stations/stations.component';
import {MyAccountComponent} from './pages/my-account/my-account.component';
import {NotificationsComponent} from './pages/notifications/notifications.component';
import {StationComponent} from './pages/station/station.component';
import {LegalNoticeComponent} from './pages/legal-notice/legal-notice.component';
import {GalleryModule} from 'ng-gallery';
import {HomeComponent} from './pages/home/home.component';
import {LightboxModule} from 'ng-gallery/lightbox';
import {DebugToolsComponent} from './pages/event/debug-tools/debug-tools.component';
import {LocalDatePipe} from './common/utcdate.pipe';
import {StationNamePipe} from './pipes/station-name.pipe';
import {ShotDetectionComponent} from 'src/app/pages/shot-detection/shot-detection.component';
import {ExovaMapComponent} from './common/exova-map/exova-map/exova-map.component';
import {AppNumberPipe} from 'src/app/pipes/app-number.pipe';
import {ImageModule} from 'primeng/image';
import {GalleriaModule} from 'primeng/galleria';
import {ButtonModule} from 'primeng/button';
import {DialogModule} from 'primeng/dialog';
import {ListboxModule} from 'primeng/listbox';
import {MultiSelectModule} from 'primeng/multiselect';
import {CheckboxModule} from 'primeng/checkbox';
import {CalendarModule} from 'primeng/calendar';
import {TableModule} from 'primeng/table';
import {AppDateTimePipe} from './pipes/app-date-time.pipe';
import {TooltipModule} from 'primeng/tooltip';
import {AudioReaderComponent} from './common/audio-reader/audio-reader.component';
import {AppTimePipe} from './pipes/app-time.pipe';
import {RestrictedAccessConditionsComponent} from './pages/restricted-access-conditions/restricted-access-conditions.component';
import {VersionComponent} from './common/version/version.component';
import {SimpleDialogComponent} from './common/simple-dialog/simple-dialog.component';
import {DialogService} from 'primeng/dynamicdialog';
import { DroneDetectionComponent } from './pages/drone-detection/drone-detection/drone-detection.component';
import {SliderModule} from 'primeng/slider';

PlotlyModule.plotlyjs = PlotlyJS;

@NgModule({
  declarations: [
    ArfangEventsFiltersComponent,
    AppComponent,
    GraphsComponent,
    ListComponent,
    LiveScopeSessionComponent,
    MapComponent,
    PeriodSelectorComponent,
    PlotterComponent,
    PropertiesSelectorComponent,
    SensorScopeComponent,
    SpectrogramComponent,
    WaterfallComponent,
    NavBarComponent,
    EventsTableComponent,
    HomeComponent,
    LivescopeComponent,
    EventComponent,
    SvgSectorComponent,
    SvgMagnitudeComponent,
    LoginComponent,
    UsersComponent,
    UserCreateComponent,
    AlertDialogComponent,
    AccountComponent,
    PasswordComponent,
    PasswordProxyComponent,
    InfrasonicEventExampleComponent,
    AzimuthCorrelogramComponent,
    StationsComponent,
    MyAccountComponent,
    NotificationsComponent,
    StationComponent,
    LegalNoticeComponent,
    DebugToolsComponent,
    LocalDatePipe,
    StationNamePipe,
    ShotDetectionComponent,
    ExovaMapComponent,
    AppNumberPipe,
    AppDateTimePipe,
    AudioReaderComponent,
    AppTimePipe,
    RestrictedAccessConditionsComponent,
    VersionComponent,
    SimpleDialogComponent,
    DroneDetectionComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    PlotlyModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonToggleModule,
    MatButtonModule,
    MatSelectModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatInputModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatRadioModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatTabsModule,
    MatSliderModule,
    MatRippleModule,
    MatMenuModule,
    MatToolbarModule,
    MatDividerModule,
    MatCheckboxModule,
    GalleryModule,
    LightboxModule,
    ImageModule,
    GalleriaModule,
    ButtonModule,
    DialogModule,
    ListboxModule,
    MultiSelectModule,
    CheckboxModule,
    CalendarModule,
    TableModule,
    TooltipModule,
    SliderModule,
  ],
  providers: [
    MatDatepickerModule,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [ConfigurationService, HttpClient]
    },
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    DialogService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
