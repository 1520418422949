import {Injectable} from '@angular/core';
import {ArfangWsService} from 'src/app/services/arfang-ws/arfang-ws.service';
import {DroneDetection} from 'src/app/services/arfang-ws/drone-detection/drone-detection-ws-model';

@Injectable({
  providedIn: 'root'
})
export class DroneDetectionWsService {

  private readonly ENDPOINT: string = 'drone-detection';

  constructor(private arfangWsService: ArfangWsService) {
  }

  getDroneDetections(): Promise<DroneDetection[]> {
    return this.arfangWsService.get(this.ENDPOINT);
  }

}
